import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper/modules"
import { getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import "aos/dist/aos.css"

import Section from "./Section"
import arrowLeft from "../assets/icons/arrow-forward-left.svg"
import arrowRight from "../assets/icons/arrow-forward-right.svg"
import "swiper/css"

const HowWeDo = ({ title, items }) => {
  const [mobileDetect, setMobileDetect] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setMobileDetect(true)
      }
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <Section additionalClasses="section has-background-bis has-text-white fancy-copy-section has-background-black-bis">
        <div className="section-title">
          <h2 className="is-size-3 is-size-3-mobile has-text-weight-bold">
            {title ? parse(title) : "How we do it."}
          </h2>
        </div>
        <div className="page-content">
          <div className="fancy-copy-swipe" id="fancy-swiper">
            <div
              role="button"
              className="arrow-button arrow-button-prev"
              aria-label="Prev slide"
            >
              <img src={arrowLeft} alt="" className="icon" />
            </div>
            <div
              role="button"
              className=" arrow-button arrow-button-next"
              aria-label="Next slide"
            >
              <img src={arrowRight} alt="" className="icon" />
            </div>

            <Swiper
              className="fancy-copy-list"
              style={{
                "--swiper-pagination-color": "#FFF335",
                "--swiper-pagination-bullet-inactive-color": "#5F5B11",
                "--swiper-pagination-bullet-inactive-opacity": "1",
                "--swiper-navigation-size": "25px",
                paddingBottom: "50px",
              }}
              modules={[Navigation, Pagination]}
              navigation={{
                prevEl: ".arrow-button-prev",
                nextEl: ".arrow-button-next",
              }}
              pagination={{
                clickable: true,
                bulletClass: `swiper-pagination-bullet`,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                769: {
                  gap: 50,
                  fill: "row",
                  grid: {
                    rows: 3,
                    fill: "row",
                  },
                  style: {
                    paddingBottom: "0",
                  },
                  noSwiping: true,
                  noSwipingClass: "fancy-copy-list",
                  cleanStyles: true,
                  deleteInstance: true,
                  navigation: false,
                  pagination: false,
                },
              }}
            >
              {items.map((item,index) => {
                return (
                  <SwiperSlide
                    key={index}
                    data-aos="fade-in"
                    data-aos-offset="10"
                    data-aos-delay={
                      index === 0 ? "100" : index === 1 ? "300" : "500"
                    }
                    data-aos-duration="1000"
                    className="fancy-copy-item has-text-white"
                    data-aos-once="false"
                  >
                    <div className="icon">
                      <img
                        src={getImage(item.icon.localFile).images.fallback.src}
                        alt=""
                        className="icon__image"
                      />
                    </div>
                    <div className="content-block">
                      <h3 className="is-size-4  has-text-weight-semibold">
                        {item.title}
                      </h3>
                      <p>{parse(item.copy)}</p>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </Section>
    </>
  )
}

export default HowWeDo
